import { prefixAPI } from '@resource/api';

import {
  createReceiver,
  createSender,
  createCreateWithFile,
} from '@resource/resource';

const TWO_FACTOR_REQUEST = prefixAPI('2factor/request-confirmation/')
const TWO_FACTOR_REQUEST_CONFIRM = prefixAPI('2factor/confirm/')

const REGISTRATION_CONSUMER = prefixAPI('registration/consumer/')
const REGISTRATION_DEVICE = prefixAPI('registration/device/')
const REGISTRATION_GET_SHORT_LINK = prefixAPI('registration/short-link/get/')

export const make2FactorReceiver = createSender(TWO_FACTOR_REQUEST)
export const confirm2FactorReceiver = createSender(TWO_FACTOR_REQUEST_CONFIRM)

export const registrationConfirmReceiver = createSender(REGISTRATION_CONSUMER)
export const registrationDeviceReceiver = createSender(REGISTRATION_DEVICE)
export const registrationGetShortLinkReceiver = createSender(REGISTRATION_GET_SHORT_LINK)

